import React from 'react';
import PropTypes from 'prop-types';
import { Containerwrap } from './styles';

const Container = ({ children, ...restProps }) => {
  return <Containerwrap {...restProps}>{children}</Containerwrap>;
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
