import React from 'react';
import PropTypes from 'prop-types';
import { Colwrap } from './styles';

const Col = ({ children, ...restProps }) => {
  return <Colwrap {...restProps}>{children}</Colwrap>;
};

Col.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Col;
